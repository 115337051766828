<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Category" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                  :class="{'is-invalid': isTabInvalid(title)}"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Section title"
                  v-model="sectionTitle"
                  @input="delete errors.section_title"
                  :error="!!errors.section_title"
                  :error-messages="errors.section_title"
                />
                <va-select
                  label="Section theme *"
                  v-model="sectionTheme"
                  :options="sectionThemes"
                  :error="!!errors.section_theme"
                  @input="delete errors.section_theme"
                  no-clear
                />
                <va-select
                  label="Section color *"
                  v-model="sectionColor"
                  :options="sectionColors"
                  :error="!!errors.section_color"
                  @input="delete errors.section_color"
                  no-clear
                />
                <va-card class="mb-3" title="Image">
                  <div v-if="imageExisting">
                    <a style="display: block;" target="_blank" :href="imageExisting"><img style="max-width: 400px;" :src="imageExisting"></a>
                    <div><va-button small color="danger" @click="imageExisting = deleteImage(imageId) ? '' : imageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.image"
                    v-model="image"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('image', 'ActivityCategoryImage', 'ActivityCategory[image]', image[0])">Завантажити</va-button>
                  <div v-if="errors.image" style="color: red;">{{ errors.image[0] }}</div>
                </va-card>
                <va-input
                  label="Label *"
                  v-model="label"
                  @input="delete errors.label"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                />
                <va-input
                  label="Alias"
                  v-model="alias"
                  @input="delete errors.alias"
                  :error="!!errors.alias"
                  :error-messages="errors.alias"
                />
                <va-input
                  type="textarea"
                  label="Description *"
                  v-model="description"
                  @input="delete errors.description"
                  :error="!!errors.description"
                  :error-messages="errors.description"
                />
                <va-card class="mb-3" title="Video">
                  <div v-if="videoExisting">
                    <a class="link" style="display: block;" target="_blank" :href="videoExisting">{{ videoExisting }}</a>
                    <div><va-button small color="danger" @click="videoExisting = deleteImage(videoId) ? '' : videoExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити новий файл</span>
                  <va-file-upload
                    dropzone
                    v-model="video"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.main_video" style="color: red;">{{ errors.main_video[0] }}</div>
                  <va-button @click="uploadImage('main_video', 'ActivityCategoryMain_video', 'ActivityCategory[main_video]', video[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  type="textarea"
                  label="Second Description *"
                  v-model="secondDescription"
                  @input="delete errors.second"
                  :error="!!errors.second"
                  :error-messages="errors.second"
                />
                <va-card class="mb-3" title="Second Description Image">
                  <div v-if="secondDescriptionImageExisting">
                    <a style="display: block;" target="_blank" :href="secondDescriptionImageExisting"><img style="max-width: 400px;" :src="secondDescriptionImageExisting"></a>
                    <div><va-button small color="danger" @click="secondDescriptionImageExisting = deleteImage(secondDescriptionImageId) ? '' : secondDescriptionImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.description_image"
                    v-model="secondDescriptionImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('description_image', 'ActivityCategoryMain_description_image', 'ActivityCategory[description_image]', secondDescriptionImage[0])">Завантажити</va-button>
                  <div v-if="errors.description_image" style="color: red;">{{ errors.description_image[0] }}</div>
                </va-card>
                <va-input
                  label="News title"
                  v-model="newsTitle"
                  @input="delete errors.news_title"
                  :error="!!errors.news_title"
                  :error-messages="errors.news_title"
                />
                <va-input
                  label="Seconds news title"
                  v-model="secondNewsTitle"
                  @input="delete errors.second_news_title"
                  :error="!!errors.second_news_title"
                  :error-messages="errors.second_news_title"
                />
                <va-input
                  label="Read more label *"
                  v-model="readMoreLabel"
                  @input="delete errors.read_more_label"
                  :error="!!errors.read_more_label"
                  :error-messages="errors.read_more_label"
                />
                <va-input
                  label="Side pagination title *"
                  v-model="sidePaginationTitle"
                  @input="delete errors.side_pagination_title"
                  :error="!!errors.side_pagination_title"
                  :error-messages="errors.side_pagination_title"
                />
                <va-toggle small v-model="published" label="Published"></va-toggle>
                <va-input
                  label="Position"
                  v-model.number="position"
                  @input="delete errors.position"
                  :error="!!errors.position"
                  :error-messages="errors.position"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="First string"
                  v-model="firstString"
                  @input="delete errors.main_first"
                  :error="!!errors.main_first"
                  :error-messages="errors.main_first"
                />
                <va-input
                  label="Second string *"
                  v-model="secondString"
                  @input="delete errors.main_second"
                  :error="!!errors.main_second"
                  :error-messages="errors.main_second"
                />
                <va-input
                  label="Third string *"
                  v-model="thirdString"
                  @input="delete errors.main_third"
                  :error="!!errors.main_third"
                  :error-messages="errors.main_third"
                />
                <va-input
                  label="Description Label *"
                  v-model="descriptionLabel"
                  @input="delete errors.main_description_label"
                  :error="!!errors.main_description_label"
                  :error-messages="errors.main_description_label"
                />
                <va-input
                  type="textarea"
                  label="Description Text *"
                  v-model="descriptionText"
                  @input="delete errors.main_description_text"
                  :error="!!errors.main_description_text"
                  :error-messages="errors.main_description_text"
                />
                <va-card class="mb-3" title="Image">
                  <div v-if="homeImageExisting">
                    <a style="display: block;" target="_blank" :href="homeImageExisting"><img style="max-width: 400px;" :src="homeImageExisting"></a>
                    <div><va-button small color="danger" @click="homeImageExisting = deleteImage(homeImageId) ? '' : homeImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.main_image"
                    v-model="homeImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('main_image', 'ActivityCategoryMain_image', 'ActivityCategory[main_image]', homeImage[0])">Завантажити</va-button>
                  <div v-if="errors.main_image" style="color: red;">{{ errors.main_image[0] }}</div>
                </va-card>
                <va-toggle small v-model="isFullscreen" label="Is fullscreen"></va-toggle>
                <va-input
                  label="Button Label *"
                  v-model="homeButtonLabel"
                  @input="delete errors.btn_label"
                  :error="!!errors.btn_label"
                  :error-messages="errors.btn_label"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-input
                  label="About title *"
                  v-model="aboutTitle"
                  @input="delete errors.about_title"
                  :error="!!errors.about_title"
                  :error-messages="errors.about_title"
                />
                <va-input
                  type="textarea"
                  label="About description"
                  v-model="aboutDescription"
                  @input="delete errors.about_description"
                  :error="!!errors.about_description"
                  :error-messages="errors.about_description"
                />
                <va-input
                  label="About button label"
                  v-model="aboutButtonLabel"
                  @input="delete errors.about_button_label"
                  :error="!!errors.about_button_label"
                  :error-messages="errors.about_button_label"
                />
                <va-card class="mb-3" title="About Image">
                  <div v-if="aboutImageExisting">
                    <a style="display: block;" target="_blank" :href="aboutImageExisting"><img style="max-width: 400px;" :src="aboutImageExisting"></a>
                    <div><va-button small color="danger" @click="aboutImageExisting = deleteImage(aboutImageId) ? '' : aboutImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.about_image"
                    v-model="aboutImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('about_image', 'ActivityCategoryMain_about_image', 'ActivityCategory[about_image]', aboutImage[0])">Завантажити</va-button>
                  <div v-if="errors.about_image" style="color: red;">{{ errors.about_image[0] }}</div>
                </va-card>
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 3">
                <va-input
                  label="Meta Title"
                  v-model="metaTitle"
                />
                <va-input
                  label="Meta Description"
                  type="textarea"
                  v-model="metaDescription"
                />
                <va-input
                  label="Seo Title"
                  v-model="seoTitle"
                />
                <div style="margin-bottom: 15px;">
                  <span>Seo Text</span>
                  <ckeditor v-model="seoText" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.home_seo_text" style="color: red;">{{ errors.home_seo_text[0] }}</div>
                </div>
                <va-input
                  label="SEO OpenGraph Title"
                  v-model="seoOpenGraphTitle"
                />
                <va-input
                  type="textarea"
                  label="SEO OpenGraph Description"
                  v-model="seoOpenGraphDescription"
                />
                <va-card class="mb-3" title="SEO OpenGraph Image">
                  <div v-if="seoOpenGraphImageExisting">
                    <a style="display: block;" target="_blank" :href="seoOpenGraphImageExisting"><img style="max-width: 400px;" :src="seoOpenGraphImageExisting"></a>
                    <div><va-button small color="danger" @click="seoOpenGraphImageExisting = deleteImage(seoOpenGraphImageId) ? '' : seoOpenGraphImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="seoOpenGraphImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('image', null, 'SeoEntity[image]', seoOpenGraphImage[0], 'common\\models\\SeoEntity')">Завантажити</va-button>
                </va-card>
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      sectionTitle: '',
      sectionTheme: '',
      sectionThemes: [],
      sectionColors: [],
      sectionColor: '',
      image: [],
      imageId: '',
      imageExisting: '',
      label: '',
      alias: '',
      description: '',
      video: [],
      videoId: '',
      videoExisting: '',
      secondDescription: '',
      secondDescriptionImage: [],
      secondDescriptionImageId: '',
      secondDescriptionImageExisting: '',
      newsTitle: '',
      secondNewsTitle: '',
      readMoreLabel: '',
      sidePaginationTitle: '',
      position: '',
      published: false,

      firstString: '',
      secondString: '',
      thirdString: '',
      descriptionLabel: '',
      descriptionText: '',
      homeImage: [],
      homeImageId: '',
      homeImageExisting: '',
      isFullscreen: false,
      homeButtonLabel: '',

      aboutTitle: '',
      aboutDescription: '',
      aboutButtonLabel: '',
      aboutImage: [],
      aboutImageId: '',
      aboutImageExisting: '',

      seoId: null,
      metaTitle: '',
      metaDescription: '',
      seoTitle: '',
      seoText: '',
      seoOpenGraphTitle: '',
      seoOpenGraphDescription: '',
      seoOpenGraphImage: [],
      seoOpenGraphImageId: '',
      seoOpenGraphImageExisting: '',

      sign: '',

      errors: [],

      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_SHKIL_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      tabTitles: ['Main', 'Home', 'About page', 'Seo'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
    const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
    this.sign = generateRandomString(32)
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    isTabInvalid (name) {
      const tab = this.tabTitles.findIndex(item => item === name)

      if (tab === 0) {
        return !!this.errors.section_title || !!this.errors.section_color || !!this.errors.section_theme ||
          !!this.errors.image || !!this.errors.label || !!this.errors.alias || !!this.errors.main_video || !!this.errors.description ||
          !!this.errors.second || !!this.errors.description_image || !!this.errors.news_title || !!this.errors.second_news_title ||
          !!this.errors.read_more_label || !!this.errors.side_pagination_title || !!this.errors.position
      } else if (tab === 1) {
        return !!this.errors.main_first || !!this.errors.main_second || !!this.errors.main_third || !!this.errors.main_description_label || !!this.errors.main_description_text ||
          !!this.errors.main_image || !!this.errors.btn_label
      } else if (tab === 2) {
        return !!this.errors.about_title || !!this.errors.about_description || !!this.errors.about_button_label || !!this.errors.about_image
      } else if (tab === 3) {
        return !!this.errors.aboutpage_team_title || !!this.errors.aboutpage_team_content ||
          !!this.errors.aboutpage_team_item_title || !!this.errors.aboutpage_team_item_content ||
          !!this.errors.aboutpage_team_btn_label
      }
      return false
    },
    uploadImage (attr, entityAttribute, param, file, model = 'backend\\modules\\activity\\models\\ActivityCategory') {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('model_name', model)
      formData.append('attribute', attr)
      if (entityAttribute !== null) {
        formData.append('entity_attribute', entityAttribute)
        if (this.$attrs.id) {
          formData.append('id', this.$attrs.id)
        } else {
          formData.append('sign', this.sign)
        }
      } else {
        formData.append('id', this.seoId ? this.seoId : 0)
      }
      formData.append(param, file)
      formData.append('file_id', '0')
      formData.append('multiple', '0')

      axios.post(`${process.env.VUE_APP_SHKIL_API_URL}/admin/upload?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_SHKIL_API_URL}/admin/upload/${id}?lang=${this.locale}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      const data = {
        section_title: this.sectionTitle,
        section_theme: this.sectionTheme ? this.sectionTheme.id : null,
        section_color: this.sectionColor ? this.sectionColor.id : null,
        label: this.label,
        alias: this.alias,
        description: this.description,
        second: this.secondDescription,
        main_first: this.firstString,
        main_second: this.secondString,
        main_third: this.thirdString,
        main_description_label: this.descriptionLabel,
        main_description_text: this.descriptionText,
        btn_label: this.homeButtonLabel,
        about_title: this.aboutTitle,
        about_button_label: this.aboutButtonLabel,
        about_description: this.aboutDescription,
        news_title: this.newsTitle,
        second_news_title: this.secondNewsTitle,
        read_more_label: this.readMoreLabel,
        side_pagination_title: this.sidePaginationTitle,
        home_is_fullscreen: this.isFullscreen ? 1 : 0,
        published: this.published ? 1 : 0,
        position: this.position,

        SeoEntity: {
          title: this.metaTitle,
          description: this.metaDescription,
          text: this.seoText,
          og_title: this.seoOpenGraphTitle,
          og_description: this.seoOpenGraphDescription,
          seo_title: this.seoTitle,
        },

        sign: this.sign ? this.sign : null,
      }
      if (this.$attrs.id) { // update
        axios.put(`${process.env.VUE_APP_SHKIL_API_URL}/admin/activity-category/${this.$attrs.id}?lang=${this.locale}`, data)
          .then(response => {
            this.$router.push({ name: 'shkil-activity-categories' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_SHKIL_API_URL}/admin/activity-category?lang=${this.locale}`, data)
          .then(response => {
            this.$router.push({ name: 'shkil-activity-categories' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_SHKIL_API_URL}/admin/activity-category/info?lang=${this.locale}`)
        .then(response => {
          this.sectionThemes = response.data.themes
          this.sectionColors = response.data.colors

          if (this.$attrs.id) {
            axios.get(`${process.env.VUE_APP_SHKIL_API_URL}/admin/activity-category/${this.$attrs.id}?lang=${this.locale}`)
              .then(response => {
                console.log(response.data)
                this.sectionTitle = response.data.sectionTitle
                this.sectionTheme = this.sectionThemes.find(i => i.id === response.data.sectionTheme)
                this.sectionColor = this.sectionColors.find(i => i.id === response.data.section_color)
                this.label = response.data.label
                this.alias = response.data.alias
                this.description = response.data.description
                this.secondDescription = response.data.second
                this.firstString = response.data.main_first
                this.secondString = response.data.main_second
                this.thirdString = response.data.main_third
                this.descriptionLabel = response.data.main_description_label
                this.descriptionText = response.data.main_description_text
                this.homeButtonLabel = response.data.btn_label
                this.aboutTitle = response.data.about_title
                this.aboutButtonLabel = response.data.about_button_label
                this.aboutDescription = response.data.about_description
                this.newsTitle = response.data.news_title
                this.secondNewsTitle = response.data.second_news_title
                this.readMoreLabel = response.data.read_more_label
                this.sidePaginationTitle = response.data.side_pagination_title
                this.isFullscreen = !!response.data.home_is_fullscreen
                this.published = !!response.data.published
                this.position = response.data.position

                this.imageExisting = response.data.image ? process.env.VUE_APP_SHKIL_ADMIN + response.data.image : ''
                this.imageId = response.data.imageId
                this.videoExisting = response.data.mainVideo ? process.env.VUE_APP_SHKIL_ADMIN + response.data.mainVideo : ''
                this.videoId = response.data.mainVideoId
                this.secondDescriptionImageExisting = response.data.secondDescImage ? process.env.VUE_APP_SHKIL_ADMIN + response.data.secondDescImage : ''
                this.secondDescriptionImageId = response.data.secondDescImageId
                this.homeImageExisting = response.data.mainImage.src ? process.env.VUE_APP_SHKIL_ADMIN + response.data.mainImage.src : ''
                this.homeImageId = response.data.homeImageId
                this.aboutImageExisting = response.data.aboutImage ? process.env.VUE_APP_SHKIL_ADMIN + response.data.aboutImage : ''
                this.aboutImageId = response.data.aboutImageId

                this.seoId = response.data.seo.id
                this.metaTitle = response.data.seo.title
                this.metaDescription = response.data.seo.description
                this.seoText = response.data.seo.text
                this.seoOpenGraphTitle = response.data.seo.og_title
                this.seoOpenGraphDescription = response.data.seo.og_description
                this.seoTitle = response.data.seo.seo_title
                this.seoOpenGraphImageExisting = response.data.seo.og_image ? process.env.VUE_APP_SHKIL_ADMIN + response.data.seo.og_image : ''
                this.seoOpenGraphImageId = response.data.seo.og_image_id
              })
              .catch(error => {
                console.log(error)
                this.showToast('Error')
              })
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

.va-tab {
  &.is-invalid {
    opacity: 1 !important;
    color: red !important;
  }
}
</style>
